var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"statistics-header"},[_c('div'),_c('h1',{staticClass:"title"},[_vm._v("困人/故障/不文明行为历史统计")]),_c('div',{staticClass:"time-select"},[_c('div',{staticClass:"item",class:_vm.timeSelect === '00' ? 'active' : '',on:{"click":function($event){return _vm.changeTimeSelect('00')}}},[_vm._v(" 周 ")]),_c('div',{staticClass:"item",class:_vm.timeSelect === '11' ? 'active' : '',on:{"click":function($event){return _vm.changeTimeSelect('11')}}},[_vm._v(" 月 ")]),_c('div',{staticClass:"item",class:_vm.timeSelect === '22' ? 'active' : '',on:{"click":function($event){return _vm.changeTimeSelect('22')}}},[_vm._v(" 年 ")])])]),_c('div',{staticClass:"statistics-wrapper"},[_c('el-row',{staticClass:"full-height"},[_c('el-col',{staticClass:"full-vertical-wrapper",attrs:{"span":12}},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("故障类型统计")]),_c('div',{style:(`width:100%;height:350px;zoom:${_vm.zoom};transform:scale(${1 /
          _vm.zoom});transform-origin:0 0`),attrs:{"id":"E_zkcl"}})]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"double"},[_c('div',{staticClass:"double-item",class:_vm.choose == '0' ? 'active' : '',on:{"click":function($event){return _vm.changeSelect(0)}}},[_vm._v(" 电梯故障工单走势图 ")]),_c('div',{staticClass:"double-item",class:_vm.choose == '1' ? 'active' : '',on:{"click":function($event){return _vm.changeSelect(1)}}},[_vm._v(" 不文明行为历史走势图 ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.choose == 0),expression:"choose == 0"}],style:(`width:100%;height:350px;zoom:${_vm.zoom};transform:scale(${1 /
          _vm.zoom});transform-origin:0 0`),attrs:{"id":"history"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.choose == 1),expression:"choose == 1"}],style:(`width:100%;height:350px;zoom:${_vm.zoom};transform:scale(${1 /
          _vm.zoom});transform-origin:0 0`),attrs:{"id":"Uncivilize"}})])]),_c('el-col',{staticClass:"full-vertical-wrapper",attrs:{"span":12}},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("历史维保统计")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"repairs-progress",staticStyle:{"flex":"2"}},[_c('div',{staticClass:"repairs-tabs"},[_c('div',{staticClass:"repairs-item"},[_c('span',{staticClass:"repairs-title"},[_vm._v("总维保工单")]),_c('span',{staticClass:"num"},[_vm._v(_vm._s(_vm.maintenData.orderTotal))])]),_c('div',{staticClass:"repairs-item"},[_c('span',{staticClass:"repairs-title"},[_vm._v("已确认/已完成工单")]),_c('span',{staticClass:"num"},[_vm._v(_vm._s(_vm.maintenData.finishOrderTotal))])])]),_c('div',{staticClass:"progress-group"},_vm._l((_vm.workOrderStatusProData),function(item,index){return _c('div',{key:index,staticClass:"progressitem"},[_c('el-progress',{attrs:{"type":"circle","width":130,"percentage":item.proportion - 0,"stroke-width":16,"show-text":false,"color":"#28C76F"}}),_c('div',{staticClass:"progress-inner"},[_c('div',{staticClass:"progress"},[_vm._v(_vm._s(item.proportion)+"%")]),_c('div',{staticClass:"total"},[_vm._v(" "+_vm._s(item.key == 'normal_maintenance' ? '正常维保' : item.key == 'be_overdue_maintenance' ? '超期维保' : '缺失维保')+": "+_vm._s(item.number)+" ")])])],1)}),0)]),_c('div',{staticStyle:{"flex":"3"}},[_c('div',{staticClass:"history-charts"},[_c('div',{staticClass:"history-title"},[_vm._v("历史维保数据走势")]),_c('div',{style:(`width:100%;height:180px;zoom:${_vm.zoom};transform:scale(${1 /
          _vm.zoom});transform-origin:0 0`),attrs:{"id":"historymaintenance"}})])])])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("历史困人救援")]),_c('div',{staticClass:"rescue-wrapper"},[_c('div',{staticClass:"rescue-tabs"},[_c('div',{staticClass:"trapped-group"},_vm._l((_vm.trappedData),function(item,index){return _c('div',{key:'trapped' + index,staticClass:"trappedDatat"},[_c('span',{staticClass:"trappedDatatitle"},[_vm._v(_vm._s(item.key))]),_c('span',{staticClass:"trappedDatanum"},[_vm._v(_vm._s(item.number))])])}),0),_c('div',{staticClass:"times-group"},[_c('div',{staticClass:"times-group-item"},[_c('div',{staticClass:"times-group-title",staticStyle:{"color":"#000"}},[_c('p',[_vm._v("救援到场")]),_c('p',[_vm._v("平均时间")])]),_c('div',{staticClass:"times"},[_vm._v(_vm._s(_vm.avg_time))])]),_c('div',{staticClass:"times-group-item"},[_c('div',{staticClass:"times-group-title",staticStyle:{"color":"#000"}},[_c('p',[_vm._v("救援解困")]),_c('p',[_vm._v("平均时间")])]),_c('div',{staticClass:"times"},[_vm._v(_vm._s(_vm.avg_time2))])])])]),_c('div',{staticClass:"rescue-table"},[_c('el-table',{staticClass:"default-table with-top-bd",attrs:{"data":_vm.trappedHisData,"row-style":{ height: '50px' },"stripe":""}},[_c('el-table-column',{attrs:{"label":"电梯名称","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.elevator_name)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"困人日期/时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.occur_time)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"视频","width":"80","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.huiKanClick(
                          scope.row.fault_id,
                          scope.row.elevator_name
                        )}}},[_vm._v(" 回看 ")])]}}])})],1)],1)])])])],1)],1),_c('Modal',{attrs:{"footer-hide":"","mask":true,"mask-closable":false,"scrollable":true,"class-name":"vertical-center-modal","title":"详情","width":"950"},model:{value:(_vm.modaDetail),callback:function ($$v) {_vm.modaDetail=$$v},expression:"modaDetail"}},[_c('div',{staticStyle:{"background":"#fff"}},[_c('Row',{attrs:{"gutter":10}},[_c('Col',{staticClass:"flexVideo",attrs:{"lg":12,"md":12,"sm":12,"xs":12}},[_c('Tabs',{attrs:{"size":"small"}},[_c('TabPane',{staticClass:"TimeDiv",attrs:{"label":"视频"}},[(_vm.videoUrl != '' && _vm.videoUrl != null)?_c('video',{ref:"video",attrs:{"src":_vm.videoUrl,"controls":""}},[_vm._v(" 您的浏览器不支持video元素。 ")]):_vm._e(),_c('br'),(!_vm.videoUrl)?_c('font',{staticStyle:{"margin-left":"205px"}},[_vm._v("暂无视频")]):_vm._e()],1),_c('TabPane',{attrs:{"label":"图片"}},[(_vm.faultImageList.length !== 0)?_c('Scroll',_vm._l((_vm.faultImageList),function(item,index){return _c('div',{key:index,staticStyle:{"margin":"32px 0"},attrs:{"dis-hover":""}},[_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":item}})])}),0):_vm._e(),_c('br'),(_vm.faultImageList.length === 0)?_c('font',{staticStyle:{"margin-left":"210px"}},[_vm._v("暂无图片")]):_vm._e()],1)],1)],1),_c('Col',{attrs:{"lg":12,"md":12,"sm":12,"xs":12}},[_c('CellGroup',[_c('Cell',{attrs:{"extra":_vm.faultInfoDetail ? _vm.faultInfoDetail.v_fault_id + '' : '--',"title":"故障ID"}}),_c('Cell',{attrs:{"extra":_vm.faultInfoDetail ? _vm.faultInfoDetail.v_elevator_name + '' : '--',"title":"电梯编号"}}),_c('Cell',{attrs:{"extra":_vm.faultInfoDetail.v_address
                  ? _vm.faultInfoDetail.v_address + ''
                  : '--',"title":"电梯地址"}}),_c('Cell',{attrs:{"extra":_vm.faultInfoDetail.v_elevator_type
                  ? _vm.faultInfoDetail.v_elevator_type + ''
                  : '--',"title":"电梯类型"}}),_c('Cell',{attrs:{"extra":_vm.faultInfoDetail.dt_report_time
                  ? _vm.faultInfoDetail.dt_report_time + ''
                  : '--',"title":"上报时间"}}),_c('Cell',{attrs:{"extra":_vm.faultInfoDetail.dt_end_time
                  ? _vm.faultInfoDetail.dt_end_time + ''
                  : '--',"title":"结束时间"}}),_c('Cell',{attrs:{"extra":_vm.faultInfoDetail
                  ? _vm.faultInfoDetail.i_fault_type +
                    '-' +
                    _vm.faultInfoDetail.v_fault_name +
                    ''
                  : '--',"title":"故障类型"}}),_c('Cell',{attrs:{"extra":_vm.faultInfoDetail.v_fault_second_type
                  ? _vm.faultInfoDetail.v_fault_second_type +
                    '-' +
                    _vm.faultInfoDetail.v_fault_second_name +
                    ''
                  : '--',"title":"故障子类型"}}),_c('Cell',{attrs:{"extra":_vm.faultInfoDetail ? _vm.faultInfoDetail.i_fault_num + '' : '--',"title":"上报次数"}}),_c('Cell',{attrs:{"extra":_vm.faultInfoDetail
                  ? _vm.faultInfoDetail.i_mode_status_name + ''
                  : '--',"title":"所处服务模式"}}),_c('Cell',{attrs:{"extra":_vm.faultInfoDetail.i_floor ? _vm.faultInfoDetail.i_floor + '' : '--',"title":"故障时梯楼层"}}),_c('Cell',{attrs:{"extra":_vm.faultInfoDetail ? _vm.faultInfoDetail.i_status_name + '' : '--',"selected":"","title":"当前故障状态"}})],1)],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }