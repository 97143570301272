<template>
  <div class="page">
    <div class="statistics-header">
      <div></div>
      <h1 class="title">困人/故障/不文明行为历史统计</h1>
      <div class="time-select">
        <div
          class="item"
          :class="timeSelect === '00' ? 'active' : ''"
          @click="changeTimeSelect('00')"
        >
          周
        </div>
        <div
          class="item"
          :class="timeSelect === '11' ? 'active' : ''"
          @click="changeTimeSelect('11')"
        >
          月
        </div>
        <div
          class="item"
          :class="timeSelect === '22' ? 'active' : ''"
          @click="changeTimeSelect('22')"
        >
          年
        </div>
      </div>
    </div>
    <div class="statistics-wrapper">
      <el-row class="full-height">
        <el-col :span="12" class="full-vertical-wrapper">
          <div class="item">
            <div class="title">故障类型统计</div>
            <div id="E_zkcl" :style="
          `width:100%;height:350px;zoom:${zoom};transform:scale(${1 /
            zoom});transform-origin:0 0`
        "></div>
          </div>
          <div class="item">
            <div class="double">
              <div
                class="double-item"
                @click="changeSelect(0)"
                :class="choose == '0' ? 'active' : ''"
              >
                电梯故障工单走势图
              </div>
              <div
                class="double-item"
                @click="changeSelect(1)"
                :class="choose == '1' ? 'active' : ''"
              >
                不文明行为历史走势图
              </div>
            </div>
            <div
              v-show="choose == 0"
              id="history"
             :style="
          `width:100%;height:350px;zoom:${zoom};transform:scale(${1 /
            zoom});transform-origin:0 0`
        "
            ></div>
            <div
              v-show="choose == 1"
              id="Uncivilize"
             :style="
          `width:100%;height:350px;zoom:${zoom};transform:scale(${1 /
            zoom});transform-origin:0 0`
        "
            ></div>
          </div>
        </el-col>
        <el-col :span="12" class="full-vertical-wrapper">
          <div class="item">
            <div class="title">历史维保统计</div>
            <div class="content">
              <div style="flex:2" class="repairs-progress">
                <div class="repairs-tabs">
                  <div class="repairs-item">
                    <span class="repairs-title">总维保工单</span>
                    <span class="num">{{ maintenData.orderTotal }}</span>
                  </div>
                  <div class="repairs-item">
                    <span class="repairs-title">已确认/已完成工单</span>
                    <span class="num">{{ maintenData.finishOrderTotal }}</span>
                  </div>
                </div>
                <div class="progress-group">
                  <div
                    class="progressitem"
                    v-for="(item, index) in workOrderStatusProData"
                    :key="index"
                  >
                    <el-progress
                      type="circle"
                      :width="130"
                      :percentage="item.proportion - 0"
                      :stroke-width="16"
                      :show-text="false"
                      color="#28C76F"
                    />
                    <div class="progress-inner">
                      <div class="progress">{{ item.proportion }}%</div>
                      <div class="total">
                        {{
                          item.key == 'normal_maintenance'
                            ? '正常维保'
                            : item.key == 'be_overdue_maintenance'
                            ? '超期维保'
                            : '缺失维保'
                        }}: {{ item.number }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style="flex:3">
                <div class="history-charts">
                  <div class="history-title">历史维保数据走势</div>
                  <div
                    id="historymaintenance"
                   :style="
          `width:100%;height:180px;zoom:${zoom};transform:scale(${1 /
            zoom});transform-origin:0 0`
        "
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="title">历史困人救援</div>
            <div class="rescue-wrapper">
              <div class="rescue-tabs">
                <div class="trapped-group">
                  <div
                    v-for="(item, index) in trappedData"
                    :key="'trapped' + index"
                    class="trappedDatat"
                  >
                    <span class="trappedDatatitle">{{ item.key }}</span>
                    <span class="trappedDatanum">{{ item.number }}</span>
                  </div>
                </div>
                <div class="times-group">
                  <div class="times-group-item">
                    <div style="color:#000" class="times-group-title">
                      <p>救援到场</p>
                      <p>平均时间</p>
                    </div>
                    <div class="times">{{ avg_time }}</div>
                  </div>
                  <div class="times-group-item">
                    <div style="color:#000" class="times-group-title">
                      <p>救援解困</p>
                      <p>平均时间</p>
                    </div>
                    <div class="times">{{ avg_time2 }}</div>
                  </div>
                </div>
              </div>
              <div class="rescue-table">
                <el-table
                  :data="trappedHisData"
                  :row-style="{ height: '50px' }"
                  stripe
                  class="default-table with-top-bd"
                >
                  <el-table-column label="电梯名称" width="120" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.elevator_name }}
                    </template>
                  </el-table-column>
                  <el-table-column label="困人日期/时间" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.occur_time }}
                    </template>
                  </el-table-column>
                  <el-table-column label="视频" width="80" align="center">
                    <template slot-scope="scope">
                      <el-button
                        @click="
                          huiKanClick(
                            scope.row.fault_id,
                            scope.row.elevator_name
                          )
                        "
                        type="primary"
                        size="mini"
                      >
                        回看
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <Modal
      footer-hide
      :mask="true"
      :mask-closable="false"
      :scrollable="true"
      class-name="vertical-center-modal"
      title="详情"
      v-model="modaDetail"
      width="950"
    >
      <div style="background:#fff">
        <Row :gutter="10">
          <Col :lg="12" :md="12" :sm="12" :xs="12" class="flexVideo">
            <Tabs size="small">
              <TabPane label="视频" class="TimeDiv">
                <video
                  :src="videoUrl"
                  controls
                  ref="video"
                  v-if="videoUrl != '' && videoUrl != null"
                >
                  您的浏览器不支持video元素。
                </video>
                <!-- <svg-icon :icon-class="'notViodeo'" class="svg-icon_not_video" v-if="!videoUrl" /> -->
                <br />
                <font style="margin-left:205px" v-if="!videoUrl"
                  >暂无视频</font
                >
              </TabPane>
              <TabPane label="图片">
                <Scroll v-if="faultImageList.length !== 0">
                  <div
                    :key="index"
                    dis-hover
                    style="margin: 32px 0"
                    v-for="(item, index) in faultImageList"
                  >
                    <img :src="item" style="max-width:100%" />
                  </div>
                </Scroll>
                <!-- <svg-icon :icon-class="'notImgList'" class="svg-icon_not_video" v-if="faultImageList.length === 0" /> -->
                <br />
                <font
                  style="margin-left:210px"
                  v-if="faultImageList.length === 0"
                  >暂无图片</font
                >
              </TabPane>
            </Tabs>
          </Col>
          </Col>
          <Col :lg="12" :md="12" :sm="12" :xs="12">
            <CellGroup>
              <Cell
                :extra="
                  faultInfoDetail ? faultInfoDetail.v_fault_id + '' : '--'
                "
                title="故障ID"
              />
              <Cell
                :extra="
                  faultInfoDetail ? faultInfoDetail.v_elevator_name + '' : '--'
                "
                title="电梯编号"
              />
              <Cell
                :extra="
                  faultInfoDetail.v_address
                    ? faultInfoDetail.v_address + ''
                    : '--'
                "
                title="电梯地址"
              />
              <Cell
                :extra="
                  faultInfoDetail.v_elevator_type
                    ? faultInfoDetail.v_elevator_type + ''
                    : '--'
                "
                title="电梯类型"
              />
              <Cell
                :extra="
                  faultInfoDetail.dt_report_time
                    ? faultInfoDetail.dt_report_time + ''
                    : '--'
                "
                title="上报时间"
              />
              <Cell
                :extra="
                  faultInfoDetail.dt_end_time
                    ? faultInfoDetail.dt_end_time + ''
                    : '--'
                "
                title="结束时间"
              />
              <Cell
                :extra="
                  faultInfoDetail
                    ? faultInfoDetail.i_fault_type +
                      '-' +
                      faultInfoDetail.v_fault_name +
                      ''
                    : '--'
                "
                title="故障类型"
              />
              <Cell
                :extra="
                  faultInfoDetail.v_fault_second_type
                    ? faultInfoDetail.v_fault_second_type +
                      '-' +
                      faultInfoDetail.v_fault_second_name +
                      ''
                    : '--'
                "
                title="故障子类型"
              />
              <Cell
                :extra="
                  faultInfoDetail ? faultInfoDetail.i_fault_num + '' : '--'
                "
                title="上报次数"
              />
              <Cell
                :extra="
                  faultInfoDetail
                    ? faultInfoDetail.i_mode_status_name + ''
                    : '--'
                "
                title="所处服务模式"
              />
              <Cell
                :extra="
                  faultInfoDetail.i_floor ? faultInfoDetail.i_floor + '' : '--'
                "
                title="故障时梯楼层"
              />
              <Cell
                :extra="
                  faultInfoDetail ? faultInfoDetail.i_status_name + '' : '--'
                "
                selected
                title="当前故障状态"
              />
            </CellGroup>
          </Col>
        </Row>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  proportionOfElevatorFailures,
  failureWorkOrderTrendChart,
  trendOfUncivilizedBehaviorNew,
  numberOfMaintenanceWorkOrders,
  statisticsWorkOrderStatusProportion,
  trendYearOfHistoricalMaintenance,
  statisticsOfTrappedPeopleRescue,
  theHistoryOfSingleLabor,
} from '@/api/ststistics/index.js'
import { getFaultDetail } from '@/api/repair/index.js'
export default {
  data() {
    return {
      timeSelect: '00',
      choose: '0',
      classificationParams: {
        register_number: '',
        villageId: '',
        timeFlag: '00',
        faultType: '',
        vProjectId: '8089373891892543488',
      },
      option1: {
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      },
      option2: {
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            var res = `${params[0].name} <br/>`
            for (const item of params) {
              if (item.value !== 0) {
                res += `<span style="background: ${item.color}; height:10px; width: 10px; border-radius: 50%;display: inline-block;margin-right:10px;"></span> ${item.seriesName} ：${item.value}<br/>`
              }
            }
            return res
          },
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: 'value',
        },
        series: [],
      },
      option3: {
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            var res = `${params[0].name} <br/>`
            for (const item of params) {
              if (item.value !== 0) {
                res += `<span style="background: ${item.color}; height:10px; width: 10px; border-radius: 50%;display: inline-block;margin-right:10px;"></span> ${item.seriesName} ：${item.value}<br/>`
              }
            }
            return res
          },
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: 'value',
        },
        series: [],
      },
      option4: {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [],
            type: 'line',
            areaStyle: {},
          },
        ],
      },
      maintenData: {
        orderTotal: '',
        finishOrderTotal: '',
      },
      workOrderStatusProData: {},
      trendYearData: [],
      trappedData: [],
      avg_time: '',
      avg_time2: '',
      trappedHisData: [],
      modaDetail: false,
      videoUrl: '',
      faultInfoDetail: '',
      faultImageList: '',
      zoom: 1,
    }
  },
  mounted() {
    const width =
      document.documentElement.clientWidth || document.body.clientWidth
    this.zoom = 1 / (width / 1920)
    window.addEventListener('resize', () => {
      const width =
        document.documentElement.clientWidth || document.body.clientWidth
      this.zoom = 1 / (width / 1920)
    })
    this.initPage()
  },
  methods: {
    changeTimeSelect(time) {
      this.timeSelect = time
      this.classificationParams.timeFlag = this.timeSelect
      this.$echarts.init(document.getElementById('Uncivilize')).dispose()
      this.$echarts.init(document.getElementById('history')).dispose()
      this.initPage()
    },

    changeSelect(data) {
      this.choose = data
      this.$echarts.init(document.getElementById('Uncivilize')).dispose()
      this.$echarts.init(document.getElementById('history')).dispose()
      if (this.choose == 1) {
        this.$nextTick(() => {
          this.trendOfUncivilizedBehaviorNew()
        })
      } else {
        this.$nextTick(() => {
          this.failureWorkOrderTrendChart()
        })
      }
    },

    initPage() {
      this.proportionOfElevatorFailures()
      this.$nextTick(() => {
        this.failureWorkOrderTrendChart()
        this.trendOfUncivilizedBehaviorNew()
      })
      this.numberOfMaintenanceWorkOrders()
      this.statisticsWorkOrderStatusProportion()
      this.trendYearOfHistoricalMaintenance()
      this.statisticsOfTrappedPeopleRescue()
      this.theHistoryOfSingleLabor()
    },

    // 故障类型统计
    proportionOfElevatorFailures() {
      proportionOfElevatorFailures(this.classificationParams).then((res) => {
        let myChart = this.$echarts.init(document.getElementById('E_zkcl'))
        if (res.info) {
          let arr = []
          res.info.forEach((item) => {
            arr.push({
              value: item.number,
              name: item.v_fault_name,
            })
          })
          this.option1.series[0].data = arr
        }
        myChart.setOption(this.option1)
      })
    },

    // 电梯故障工单走势图
    failureWorkOrderTrendChart() {
      let obj = { ...this.classificationParams }
      obj.currentStatus = 6
      failureWorkOrderTrendChart(obj).then((res) => {
        let myChart = this.$echarts.init(document.getElementById('history'))
        if (res.info) {
          this.option2.xAxis.data = res.info.times
          let arr = []
          res.info.datas.forEach((item) => {
            arr.push({
              name: item.title,
              type: 'bar',
              stack: 'total',
              label: {
                normal: {
                  show: true,
                  formatter: function (params) {
                    if (params.value > 0) {
                      return params.value
                    } else {
                      return ''
                    }
                  },
                },
              },
              emphasis: {
                focus: 'series',
              },
              data: item.values,
            })
          })
          this.option2.series = arr
          myChart.setOption(this.option2)
        }
      })
    },

    // 不文明行为
    trendOfUncivilizedBehaviorNew() {
      trendOfUncivilizedBehaviorNew(this.classificationParams).then((res) => {
        let myChart = this.$echarts.init(document.getElementById('Uncivilize'))
        if (res.info) {
          res.info.datas = res.info.datas.filter((item) => {
            return item.title == '关门时受阻挡' || item.title == '电动车乘梯'
          })
          this.option3.xAxis.data = res.info.times
          let arr = []
          res.info.datas.forEach((item) => {
            arr.push({
              name: item.title,
              type: 'bar',
              stack: 'total',
              label: {
                normal: {
                  show: true,
                  formatter: function (params) {
                    if (params.value > 0) {
                      return params.value
                    } else {
                      return ''
                    }
                  },
                },
              },
              emphasis: {
                focus: 'series',
              },
              data: item.values,
            })
          })
          this.option3.series = arr
          myChart.setOption(this.option3)
        }
      })
    },

    numberOfMaintenanceWorkOrders() {
      numberOfMaintenanceWorkOrders(this.classificationParams).then((res) => {
        res.info.forEach((item) => {
          if (item.Key === 'total_work_order_number') {
            this.maintenData.orderTotal = item.number
          }
          if (item.Key === 'finish_work_order_number') {
            this.maintenData.finishOrderTotal = item.number
          }
        })
      })
    },
    statisticsWorkOrderStatusProportion() {
      statisticsWorkOrderStatusProportion(this.classificationParams).then(
        (res) => {
          this.workOrderStatusProData = res.info
        }
      )
    },
    trendYearOfHistoricalMaintenance() {
      trendYearOfHistoricalMaintenance(this.classificationParams).then(
        (res) => {
          let myChart = this.$echarts.init(
            document.getElementById('historymaintenance')
          )
          if (res.info) {
            this.option4.xAxis.data = res.info.times
            console.log(this.timeSelect, ' this.timeSelect')

            this.option4.series[0].data =
              this.timeSelect == '22'
                ? res.info.datas[1].values
                : res.info.datas[0].values
          }
          myChart.setOption(this.option4)
        }
      )
    },
    statisticsOfTrappedPeopleRescue() {
      statisticsOfTrappedPeopleRescue(this.classificationParams).then((res) => {
        this.trappedData = res.info.splice(0, 3)
        if (res.info instanceof Array && this.trappedData.length > 0) {
          this.avg_time = res.info[1]['number']
          this.avg_time2 = res.info[0]['number']
        }
      })
    },

    theHistoryOfSingleLabor() {
      theHistoryOfSingleLabor(this.classificationParams).then((res) => {
        this.trappedHisData = res.info
      })
    },
    huiKanClick(faultId, elevatorName) {
      this.modaDetail = true
      if (faultId) {
        getFaultDetail(faultId).then((res) => {
          console.log(res)
          if (res.code === '0000') {
            this.faultInfoDetail = res.info.detail
            this.videoUrl = res.info.videoUrl
            this.faultImageList = res.info.imageList
          }
        })
      }
    },
  },

  watch: {
    modaDetail: function (newObj) {
      if (!newObj) {
        this.faultInfoDetail = ''
        this.videoUrl = ''
        this.faultImageList = []
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.page {
  height: 100%;
}
.statistics-header {
  display: flex;
  height: 90px;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;

  .back-btn {
    font-size: 50px;
    font-weight: bold;
    color: #fff;
  }

  .title {
    font-size: 24px;
    color: #515a6e;
  }

  .time-select {
    & > .item {
      cursor: pointer;
      display: inline-block;
      width: 60px;
      height: 30px;
      line-height: 30px;
      margin-left: 16px;
      border: 1px solid #515a6e;
      text-align: center;
      font-size: 18px;
      color: #515a6e;

      &.active {
        background-color: #515a6e;
        color: #fff;
      }
    }
  }
}

.statistics-wrapper {
  height: calc(100% - 90px);
  overflow: hidden;
  .full-height {
    height: 100%;
    .full-vertical-wrapper {
      padding: 0 20px 20px 20px;
      display: flex;
      gap: 20px;
      flex-direction: column;
      height: 100%;
      .item {
        flex: 1;
        overflow: hidden;
        border: 1px solid #515a6e;
        .title {
          height: 40px;
          line-height: 40px;
          display: inline-block;
          color: #fff;
          font-size: 16px;
          background-color: #515a6e;
          padding: 0 8px;
        }
        .double {
          height: 40px;
          line-height: 40px;
          font-size: 16px;
          background-color: #515a6e;
          color: #fff;
          display: flex;
          .double-item {
            flex: 1;
            text-align: center;
            &.active {
              box-shadow: inset 0 0 18px rgba(rgb(243, 239, 21), 0.95);
              color: #fff;
            }
          }
        }
        .content {
          height: calc(100% - 40px);
          display: flex;
          flex-direction: column;
        }
      }
    }
    .full-vertical-wrapper:nth-last-child(1) {
      padding-left: 0;
    }
  }
}
.repairs-progress {
  display: flex;

  .repairs-tabs {
    display: flex;
    flex-direction: column;
    width: 40%;
    padding: 0 50px 0 10px;
    justify-content: space-around;
    .repairs-item {
      background-color: #ddd;
      font-size: 16px;
      height: 40px;
      line-height: 40px;
      padding-left: 10px;
      margin-left: 20px;
      display: inline-block;
      .repairs-title {
        margin-right: 20px;
      }
    }
    .corner-bd-tag {
      font-size: 22px;
      color: #00deff;

      .title {
        padding-left: 10px;
      }

      .num {
        margin-left: 20px;
        color: #fff;
      }
    }
  }

  .progress-group {
    display: flex;
    flex: 1;
    justify-content: space-around;

    & > .progressitem {
      line-height: normal;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .progress-inner {
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .progress {
          padding: 0 10px 4px;
          border-bottom: 1px solid #00d9fb;
          font-size: 22px;
          color: #00d9fb;
        }

        .total {
          margin-top: 4px;
          font-size: 16px;
        }
      }
    }
  }
}
.history-charts {
  height: 100%;
  .history-title {
    display: inline-block;
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #515a6e;
    color: #fff;
  }
}
.rescue-wrapper {
  display: flex;
  height: calc(100% - 40px);
  padding: 10px 20px;
  overflow: hidden;

  .rescue-tabs {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 40%;
    padding: 0 50px 0 10px;
    justify-content: space-between;
    overflow: hidden;
    .trapped-group {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .trappedDatat {
        background-color: #ddd;
        height: 40px;
        line-height: 40px;
        padding-left: 10px;
        .trappedDatatitle {
          margin-right: 20px;
          font-size: 16px;
        }
        .trappedDatanum {
          font-size: 18px;
        }
      }
    }
    .times-group {
      height: 120px;
      display: flex;
      border: none;
      gap: 20px;
      .times-group-item {
        padding: 10px;
        box-sizing: border-box;
        flex: 1;
        display: flex;
        border: 1px solid #515a6e;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .times-group-title {
          padding-bottom: 10px;
          font-size: 16px;
          color: #00deff;
          background-size: 100% auto;
          border-bottom: 1px solid #515a6e;
        }
        .times {
          padding-top: 10px;
          font-size: 20px;
          color: #515a6e;
        }
      }
    }
  }

  .rescue-table {
    position: relative;
    height: 300px;
    flex: 1;
    width: 60%;
    padding: 0 10px;
    overflow: hidden;

    .border-tag-title {
      position: absolute;
      font-size: 16px;
      top: -50px;
    }
  }

  .rescue-table::-webkit-scrollbar {
    display: none;
  }

  .rescue-table {
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
.flexVideo video[data-v-5040ee8e] {
  height: 400px;
}
.flexVideo {
  video {
    width: 100%;
  }
}
</style>
